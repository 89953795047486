import DeleteIp from '@/layouts/Stack/components/DeleteIp.vue';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import storeMixin from '@/layouts/Stack/mixins/fetch';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorOpenStackModal, storeMixin, modals],
  computed: {},
  methods: {
    deleteIp: function (port) {
      // console.log(port, float);
      const that = this;
      return new Promise(() => {
        const selfName = 'DeleteIp';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: DeleteIp,
          closeOnBackdrop: false,
          props: {
            port: port,
          },
          text: this.$t('sure.text'),
          on: {
            port: data => {
              port = data;
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  this.updateCurrentPort(port).then(async data => {
                    if (data) {
                      this.fetchServer();
                      that.$modals.close();
                      this.showResModal('Ip-адрес удалён из порта.');
                    } else {
                      this.fetchServer();
                      this.$modals.close({ name: 'DeleteIp' });
                    }
                  });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    updateCurrentPort(payload) {
      const params = {
        port: {
          fixed_ips: payload.fixed_ips,
        },
        id: payload.portId,
        item: 'fixed_ips',
      };
      return this.$store.dispatch('moduleStack/updatePort', params).catch(e => {
        this.showError(e);
      });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     cancel: {
    //       props: { title: this.$t('close') },
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
